import { API, graphqlOperation, gqlast } from 'aws-amplify';


export const register = /* GraphQL */ `
    mutation SyncupMutation{
    register(input:{email:"$email",pwd:"$pwd",type:"$type"}){
    responseData{ 
code
message
status
errors
}
}
}
`;

export const login = /* GraphQL */ `
    mutation SyncupMutation{
    login(input:{email:"$email",pwd:"$pwd"}){
    responseData{ 
code
message
status
errors
}
}
}
`;

export const forgotPwd = /* GraphQL */ `
    mutation SyncupMutation{
    forgotPwdInit(input:{email:"$email"}){
    responseData{ 
code
message
status
errors
}

}

}
`;

export const forgotPwdConfirm = /* GraphQL */ `
    mutation SyncupMutation{
    forgotPwdConfirm(input:{code:"$code",email:"$email",pwd:"$pwd"}){
    responseData{ 
code
message
status
errors
} 
} 
}
`;

export const registerConfirm = /* GraphQL */ `
   mutation MyMutation {
  registerConfirm(input: {code: "$code", email: "$email"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const registerCodeResend = /* GraphQL */ `
  mutation CodeResendMutation {
  registerCodeResend(input: {email: "$email" }) {
    responseData {
      code
      errors
      message
      status
    }
  }
} 
`;
export const changePwd = /* GraphQL */ `
  mutation ChangePwdMutation {
    changePwd(input: { email: "$email", newPwd: "$newPwd", pwd: "$oldPwd" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;

export const refreshToken = /* GraphQL */ `
 mutation RTMutation {
    refreshToken(input: { email: "$email", token: "$token", userType: "$userType"   }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;

export const logout = /* GraphQL */ `
 mutation LogoutMutation  {
    logout (input: { email: "$email", token: "$token", tokenType: "access"  }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const timeZone = /* GraphQL */ `
 mutation TZMutation {
    timezone(input: {  code:"$code"}) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const getExpertProfile = /* GraphQL */ `
 mutation EPLMutation {
    expertPerLoad(input: { sub: "$sub" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const getClientProfile = `
mutation CPRMutation {
    clientPerLoad(input: {sub: "$sub"}) {
      responseData {
        code
        errors
        message
        status
      }
    }
  }
  `;
export const updateExpertPersonal = ` 
mutation ExpertPerMutation {
        expertPerUpdate(input: {sub: "$sub", email: "$email", userType: $userType, address1: "$address1", address2: "$address2", avatar: "$avatar", browserCountry: "$browserCountry", browserTimezone: "$browserTimezone", city: "$city", countryId: $country, firstname: "$fName", lastname: "$lName", phone: "$phone", photo: "$photo", state: "$state",zip: "$zip", timezoneId: $timeZone, profilePercentage: $percentage,otherLangs:"$otherLangs"}) {
        responseData {
            code
            errors
            message
            status
        }
        }
    }
`;
export const getExpertEducation = /* GraphQL */ `
 mutation EELMutation {
  expertEduLoad(input: {expertId: $expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const getExpertExperience = /* GraphQL */ `
 mutation EELMutation {
  expertExpLoad(input: {expertId:$expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertEducation = /* GraphQL */ `
mutation EEUMutation {
  expertEduUpdate(input: {[$ID]sub: "$sub", expertId: $expertId, degreeId: $degreeId, email: "$email", endDate: "$endDate", major: "$major", profilePercentage: $profilePercentage, school: "$school", startDate: "$startDate", countryId: $countryId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertExperience = /* GraphQL */ `
mutation EExpUpdateMutation {
  expertExpUpdate(input: {[$endDate][$ID]expertId: $expertId,company: "$company", email: "@email", sub: "$sub", current: $current, expYears: $expYears, role: "$role", startDate: "$startDate", profilePercentage: $profilePercentage}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertProfessional = /* GraphQL */ `
mutation EPUMutation {
  expertProUpdate(input: {sub: "$sub", userType: $userType, email: "$email", weeklyHrsId: $weeklyHrsId, title: "$title", indTypeIds : "$indTypeIds",skillIds: "$skillIds", resume: "$resume", profilePercentage: $percentage, profile: "$profile", expLevelId: $expLevelId, dailyRate:$dailyRate, compIds: "$compIds",compCatIds:"$compCatIds"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const getExpertProfessional = /* GraphQL */ `
mutation EPLMutation {
  expertProLoad(input: {sub: "$sub"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;



export const loadClientProfile = /* GraphQL */ `

mutation CPRMutation {
    clientPerLoad(input: {sub: "$sub"}) {
      responseData {
        code
        errors
        message
        status
      }
    }
  }

  `;


export const loadClientBusinessProfile = /* GraphQL */ `

  mutation CBLMutation {
    clientBusLoad(input: {sub: "$sub"}) {
      responseData {
        code
        errors
        message
        status
      }
    }
  }
  
  `;
export const getExpertSocial = /* GraphQL */ `
 mutation ESLMutation {
  expertSocLoad(input: {expertId: $expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertSocial = /* GraphQL */ `
mutation ESUMutation {
  expertSocUpdate(input: {[$ID]email: "$email", expertId: $expertId, linkId: $linkId, profilePercentage: $profilePercentage, sub: "$sub", url: "$url"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const getExpertCertificate = /* GraphQL */ `
 mutation ECLMutation {
    expertCerLoad(input: { expertId: $expertId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const getExpertTestimonial = /* GraphQL */ `
 mutation ECLMutation {
    expertTesLoad(input: { expertId: $expertId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}
`;
export const updateExpertTestimonial = /* GraphQL */ `
 mutation ETUMutation {
  expertTesUpdate(input: {[$ID]email: "$email", expertId: $expertId, fileName: "$fileName", name: "$name", profilePercentage: $profilePercentage, sub: "$sub", url: "$url"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const updateExpertCertificate = /* GraphQL */ `
 mutation ETUMutation {
  expertCerUpdate(input: {[$ID]email: "$email", expertId: $expertId, fileName: "$fileName", name: "$name", profilePercentage: $profilePercentage, sub: "$sub", url: "$url",certExpiryDate:"$expiryDate"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}
`;
export const ClientPreviewLoad =  /* GraphQL */ `
  mutation CALMutation {
    clientAllLoad(input: {sub: "$sub",profileUpdate:$profileupdate}) {
      responseData {
        code
        errors
        message
        status
      }
    }
  }
  `;

export const ExpertPreviewLoad =  /* GraphQL */ `
  mutation EALMutation {
  expertAllLoad(input: {sub: "$sub",profileUpdate:$profileupdate}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const ExpertRowDelete =  /* GraphQL:type: (edu/exp/cer/soc/tes)  */ `
mutation ERDMutation {
    expertRowDelete(input: {  id: $id, type: "$type" ,sub: "$sub"}) {
        responseData {
            code
            errors
            message
            status
        }
    }
} `;

export const ClientJobsLoad = `
mutation MyMutation {
  clientJobsLoad(input: {clientId: $clientId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;
export const ClientJobsUpdate = `mutation MyMutation {
jobUpdate(input: {[$ID] rangeRateTypeId:$rangeRateTypeId, clientId: $clientId,compCatIds:"$compCatIds",rateFrom:$rateFrom,rateTo:$rateTo,type:"$type", email: "$email", title: "$title", desc: "$desc", weeklyHrsId: $weeklyHrsId, statusId: 1, projLengthId: $projLengthId, skillIds: "$skillIds", hourlyRate: $hourlyRate, fixedRate: $fixedRate, expLevelId: $expLevelId, dailyRate: $dailyRate, countryId: $countryId,indTypeIds:"$indTypeIds" ,compIds: "$compIds", city: "$city", state: "$state",statusId :$statusId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;

export const ClientJobsDelete = `
mutation MyMutation {
  jobDelete(input: {clientId: $clientId, id: $id}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;


export const clientJobsRead = `
mutation MyMutation {
  jobRead(input: { guid: "$guid"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
} `;
// 100 - New/Draft, 200 - Posted, 300 - Hired, 400 - Closed, 500 - Archived ,
export const clientJobsStatusUpdate = `
mutation MyMutation {
jobStatusUpdate(input: { clientId: $clientId, jobCode: $jobCode, jobId: $id,email:"$email" }) {
    responseData {
        code
        errors
        message
        status
    }
}
}`;
export const clientDashboardCounts = `
mutation MyMutation {
    clientDashboardCounts(input: { clientId: $clientId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const searchJobs = `
mutation MyMutation {
  searchJobs(input: {country: $country, expLvlIds: "$expLvlIds", jobTypes: "$jobTypes", keyword: "$keyword", projLengthIds: "$projLengthIds", weeklyHrsIds: "$weeklyHrsIds",skillIds:"$skillIds",complianceCategoryIds:"$complianceCategoryIds",complianceStandardIds:"$complianceStandardIds",dateSince:"$dateSince",industryIds:"$industryIds",rateRangeFrom:"$rateRangeFrom",rateRangeTo:"$rateRangeTo",rateType:"$rateType"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const proposalCreate = `
mutation MyMutation {
  proposalCreate(input: {[$ID] bid: $bid, clientId: $clientId, coverLetter: "$coverLetter", email: "$email", expertId: $expertId, jobId: $jobId,attachments:"$attachments"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const proposalLoadAll = `
mutation MyMutation {
  proposalLoadAll(input: {clientId: $clientId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const searchExpert = `
mutation MyMutation {
  searchTalents(input: {jobSuccessRate:"$jobSuccessRate",avgRating:"$avgRating",otherLangs:"$otherLangs",country: $country, expLvlIds: "$expLvlIds", keyword: "$keyword" , weeklyHrsIds: "$weeklyHrsIds",skillIds:"$skillIds",complianceCategoryIds:"$complianceCategoryIds",complianceStandardsIds:"$complianceStandardIds",dateSince:"$dateSince",industryIds:"$industryIds",rateRangeFrom:"$rateRangeFrom",rateRangeTo:"$rateRangeTo",rateType:"daily"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const proposalStatusUpdate = `
mutation MyMutation {
    proposalStatusUpdate(input: { id: $id, statusCode: $statusCode,email:"$email" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const createOffer = `
mutation MyMutation {
  offerCreate(input: {[$ID] clientId: $clientId, dailyRate: $dailyRate, desc: "$desc",  expertId: $expertId, fixedRate: $fixedRate,  hourlyRate: $hourlyRate,   jobId: $jobId, proposalId: $proposalId, rateTypeId: $rateTypeId, startDate: "$startDate", title: "$title",email:"$email", weeklyHrsId: $weeklyHrsId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const loadClientOffers = `
mutation MyMutation {
  loadClientOffers(input: {clientId: $clientId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const loadOffer = `
mutation MyMutation {
    loadOffer(input: { offerId: $offerId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const clientContractsLoad = `
mutation MyMutation {
    clientContractsLoad(input: { clientId: $clientId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const loadExpertOffers = `
 mutation MyMutation {
    loadExpertOffers(input: { expertId: $expertId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const loadProposal = `
mutation MyMutation {
    loadProposal(input: { proposalId: $proposalId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const loadContract = `
mutation MyMutation {
  loadContract(input: {guid: "$guid"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`; 
export const createContract = `
mutation MyMutation {
  contractCreate(input: {task:"$task",taskDetails:"$taskDetails",dueDate:"$dueDate",amount:$amount, clientId: $clientId, rateTypeId: $rateTypeId,  desc: "$desc", dailyRate: $dailyRate,fixedRate: $fixedRate,  hourlyRate: $hourlyRate,  expertId: $expertId,   jobId: $jobId, proposalId: $proposalId,endDate:"$endDate", startDate: "$startDate", title: "$title",email:"$email", weeklyHrsId: $weeklyHrsId,offerId:$offerId,hiringPerson:"$hiringPerson" }) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const updateContract = `
mutation MyMutation {
  contractCreate(input: {[$ID] clientId: $clientId, rateTypeId: $rateTypeId,  desc: "$desc", dailyRate: $dailyRate,fixedRate: $fixedRate,  hourlyRate: $hourlyRate,  expertId: $expertId,   jobId: $jobId, proposalId: $proposalId,endDate:"$endDate", startDate: "$startDate", title: "$title",email:"$email", weeklyHrsId: $weeklyHrsId,offerId:$offerId,hiringPerson:"$hiringPerson" }) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
// here Client id - either client id or expert id
export const offerStatusUpdate = `
mutation MyMutation {
    offerStatusUpdate(input: {[reasonID] [ID] ,offerCode: $offerCode, offerId: $offerId,email:"$email" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const expertSaveJob = `
mutation MyMutation {
  saveJob(input: {expertId: $expertId, isSave: $isSave, jobId: $jobId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const expertContractsLoad = `
mutation MyMutation {
  expertContractsLoad(input: {expertId: $expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const expertContractUpdate = `
mutation MyMutation {
  expertContractUpdate(input: {[mode], contractId: $contractId, expertId: $expertId,statusCode:$statusCode }) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const clientContractTerminate = `
mutation MyMutation {
  contractClientStatusUpdate(input: { contractId: $contractId,clientId: $clientId,statusCode:$statusCode,termId:$termId,termBy:"$termBy"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const clientContractComplete = `
mutation MyMutation {
  contractClientStatusUpdate(input: { contractId: $contractId,clientId: $clientId, expertId: $expertId, statusCode:$statusCode,rating:$rating,email:"$email",feedback:"$feedback"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
//job: "applied" or "saved"
export const loadExpertJobs = `
mutation MyMutation {
  loadExpertJobs(input: {job: "$jobMode", expertId: $expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const sendMessage = `
mutation MyMutation {
    sendMessage(input: {attachment:"$attachment", email: "$email", fromTypeId: $fromTypeId, fromUserId: $fromUserId, message: "$message", toTypeId: $toTypeId, toUserId: $toUserId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;


export const expertProposals = `
mutation MyMutation {
  expertProposals(input: {expertId: $expertId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const createReportIssue = `
mutation MyMutation {
  createIssueReport(input: {desc: "$desc", email: "$email", issueCategoryId: $issueCategoryId, title: "$title", userId: $userId, userType: $userType}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const messageSummary = `
mutation MyMutation {
    messageSummary(input: { userId: $userId, userTypeId: $userTypeId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const newMessageCount = `
mutation MyMutation {
    newMessageCount(input: { userId: $userId, userTypeId: $userTypeId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const loadMessages = `
mutation MyMutation {
  loadMessages(input: {fromTypeId: $fromTypeId, fromUserId: $fromUserId, toTypeId: $toTypeId, toUserId: $toUserId}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const newAlertCount = `
mutation MyMutation {
    newAlertCount(input: { userId: $userId, userTypeId: $userTypeId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const loadAlerts = `
mutation MyMutation {
  loadAlerts(input: { userId: $userId, userTypeId: $userTypeId }) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const createAlert = `mutation MyMutation {
    createAlert(input: {[ID] alert: "$alert", alertType: "$alertType", display: $display,status: "$status", toTypeId: $toTypeId, toUserId: $toUserId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const expertDashboardCounts = `
mutation MyMutation {
  expertDashboardCounts(input: { expertId: $expertId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const createFeedback = `
mutation MyMutation {
    createFeedback(input: { clientId: $clientId, contractId: "$contractId", email: "$email", expertId: $expertId, feedback: "$feedback", jobId: $jobId, rating: "$rating", senderType: $senderType }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const loadQuestions = `
mutation MyMutation {
    loadQuestions(input: { catCode: "$catCode" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const clientJobsList = `
mutation MyMutation {
    clientJobsList(input: { clientId: $clientId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const assmntSubmit = `
 mutation AsssubmitMutation {
  assmntSubmit(input: {expertId: $expertId,
		ansq:"$ansq",ansa:"$ansa"
  }) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const accountDelete = `
mutation MyMutation {
  accountDelete(input: {id: $id, type: $type}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const FedLogin = `
 mutation FedLogin {
  loginFed(input: {fedResp: "$fedResp"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;


export const fetchCompanyProfile = `
 mutation MutationFCP {
  fetchCompanyProfile(input: {website: "$website"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;


export const siteFeedbackMutation = `
mutation SiteFeedbackMutation {
    writeSiteFeedback(input: { browser: "$browser", createdBy: "$createdBy", feedback: "$feedback", location: "$location", platform: "$platform", rating: $rating }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;


export const deleteMessage = `
mutation deleteMessage {
  deleteMessage(input: {id:$id}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const updateMessage = `
mutation updateMessage {
  updateMessage(input: {id: $id,message: "$message",attachment:"$attachment"}) {
  responseData {
    code
    errors
    message
    status
  }
}
}`;



export const paymentCreateAccount = `
mutation MyMutation {
  paymentCreateAccount(input: {city: "$city", company: "$company", country: "$country", email: "$email", firstName: "$firstName", lastName: "$lastName", state: "$state", street1: "$street1", street2: "$street2", userId: $userId, userType: $userType, zip: "$zip",url:"$url",phone:"$phone"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const paymentUserUpdate = `
mutation MyMutation {
    paymentUserUpdate(input: { stripeId: "$stripeId", userId: $userId, userType: $userType }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const paymentOnboardAccount = `
mutation MyMutation {
  paymentOnboardAccount(input: {refreshUrl: "$refreshUrl", returnUrl: "$returnUrl", stripeId: "$stripeId"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

 
export const paymentRetrieveAccount = `
mutation MyMutation {
    paymentRetrieveAccount(input: { stripeId: "$stripeId" }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const createMilestone = `mutation MyMutation2 {
 
    createMilestone(input: { amount: $amount,  contractId: $contractId, dueDate: "$dueDate", task: "$task", taskDetails: "$taskDetails",email: "$email",userId:$userId }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const updateMilestone = `mutation MyMutation2 {
  updateMilestone(input: {rejectedReason :"$rejectedReason",amount: $amount,userId:$userId ,  dueDate: "$dueDate", attachments:"$attachments", hoursSpent: $hoursSpent,statusCode:$statusCode, id: $id,  task: "$task", taskDetails: "$taskDetails", userType: $userType,email: "$email",crDueDate:"$crDueDate",crNote1:"$crNote1",crNote2:"$crNote2"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const deleteMilestone = `mutation MyMutation2 {
    deleteMilestone(input: { id: $id }) {
        responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const updateSettingNotification = ` mutation MyMutation {
    updateSettingNotification(input: { emailsEnabled: $emailsEnabled, marketingEmailsEnabled: $marketingEmailsEnabled, messagesEnabled: $messagesEnabled, userId: $userId, userType: $userType }) {
    responseData {
            code
            errors
            message
            status
        }
    }
}`;
export const stripeCardPaymentCreate = ` mutation MyMutation {
  stripePaymentCreate(input: {amount: $amount, currency: "USD", destination: "$destination", email: "$email", name: "$name", token: "$token",clientid:$clientid,expertid:$expertid,contractid:$contractid,milestoneid:$milestoneid,  paymentMethodType:"card",
          clientStripeId:"",
          bankAccountId: "",
          taskName:"$taskName",
          clientName:"$clientName"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;
export const stripeUSBankPaymentCreate = ` mutation MyMutation {
  stripePaymentCreate(input: {amount: $amount, currency: "USD", destination: "$destination", email: "$email", name: "$name", token: "",clientid:$clientid,expertid:$expertid,contractid:$contractid,milestoneid:$milestoneid,  paymentMethodType:"us_bank_account",
          clientStripeId:"$clientStripeId",
          bankAccountId: "$bankAccountId",
          taskName:"$taskName",
          clientName:"$clientName"}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;

export const userTrxnHistory = `mutation MyMutation {
    userTrxnHistory(input: { userId: $user_id, userTypeId: $user_type_id }) {
    responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const paymentHistoryUpdate = `mutation MyMutation {
    stripePaymentIntentUpdate(input: { paymentIntentId: "$payment_Id",paymentIntentStatus:"$payment_Status" }) {
    responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const saveJobInvites = `mutation MyMutation {
    saveJobInvites(input: { clientId: $clientId, expertId: $expertId, jobId: $jobId }) {
    responseData {
            code
            errors
            message
            status
        }
    }
}`;

export const loadJobsInvites = `mutation MyMutation {
    loadJobsInvites(input: { clientId: $clientId , expertId: $expertId}) {
    responseData {
            code
            errors
            message
            status
        }
    }
}`;



export const loginMfaConfirm = `mutation MFAMutation {
  loginMfaConfirm(input: {sub: "$sub", code: $code}) {
    responseData {
      code
      errors
      message
      status
    }
  }
}`;