import React, { useState, useEffect, useContext} from "react";
import PageWrapper from "../components/PageWrapper";
import Icon from '@mui/material/Icon';
import HowItWorks from "../sections/home/HowItWorks"; 
import GlobalContext from "../context/GlobalContext"; 
import { HomeFeaturedList } from "../utils/GetAPIUtils"; 
import "../style/css/style.css";
import "../style/css/media.css";

import "../style/css/common.css";
import { useLocation } from 'react-router-dom';
export default function MainPage({ zone, navType }) {
    const gContext = useContext(GlobalContext);
    const [featuredData, setFeaturedData] = useState(null);
    const pathName = useLocation().pathname.toLowerCase();
    const hide = true;
    async function getHomeFeatureData() {
        try {
            
            const result = await HomeFeaturedList();

            if (result.data.homeFeaturedList.responseData.code == "200") {
             
                let data = JSON.parse(result.data.homeFeaturedList.responseData.message);
               
                setFeaturedData(data);
            }
           
        }
        catch (e) {

        }
    }
    useEffect(() => {
        document.title = "Home | Syncuppro";
        const user = localStorage.getItem('connect');
        if (user==null && pathName.trim().toLowerCase() == "/login") {

            gContext.toggleSignInModal();
        } 
        if (user == null && pathName.trim().toLowerCase() == "/signup") {
            gContext.toggleSignUpModal();
        }
        if (user == null && (pathName.trim().toLowerCase() == "/signupsuccess" || pathName.trim().toLowerCase() == "/signupsuccess_expert" || pathName.trim().toLowerCase() == "/signupsuccess_client") && localStorage.getItem('userEmail')) {
            gContext.toggleSignUpModal();
        }
        
        if (user == null && localStorage.getItem('userEmail') && (pathName.trim().toLowerCase() == "/signupcode" || pathName.trim().toLowerCase() == "/signupcode_expert" || pathName.trim().toLowerCase() == "/signupcode_client")) {
            gContext.toggleSignUpModal();
        }
        else if (pathName.trim().toLowerCase() == "/signupcode") {
            window.location.href = "/";
        }
        if (window.location.href.split('#').length > 1)
            window.location.href = window.location.href.replace(window.location.origin, "");
       
        const passwordChanged = localStorage.getItem('passwordChanged');      
        if (passwordChanged) {
            localStorage.removeItem('passwordChanged'); 
            gContext.toggleSuccessModal();
        }
        getHomeFeatureData();

    }, [])

  return (
      <>  
      <PageWrapper
        headerConfig={{
                  bgClass: "dynamic-sticky-bg",
                  button: navType
        }}
          >
              <div className="page-wrapper">
 
              <section className="section welcome-sec bg-gray p-relative gradient-img bg-gray">
                      <div className="left-sec bg-gray text-center">
                      <div className="section-title m-10 p-20 text-center">

                              <h1> Connect, Engage, and Grow.</h1>
                              <p className="section-desc">Be the master of your schedule. Work from comfort of your Home.</p>
                              <div class="d-grid w-full   p-20   text-center">   {!localStorage.getItem('connect') && <button id="signup3" className="btn btn-green btn-block  w-150 m-auto " type="button" onClick={(e) => {
                                  if (!localStorage.getItem('connect')) {
                                      e.preventDefault();
                                      gContext.setDefaultUserType("expert");
                                      gContext.toggleSignUpModal();
                                  }

                              }}>Sign up</button>}</div>
                      </div>                   

                       
                  </div>

                  <div className="right-sec">
                      <div className="img-wrapper">
                          <img className="img-block" src="images/workers-img.svg" alt="image" />
                      </div>
                  </div>
              </section>
            
              <HowItWorks defaultType="1" />
           
              <div className="m-20">&nbsp;</div>
                  <div className="m-20">&nbsp;</div>
              </div>
      </PageWrapper>
    </>
  );
};

