import React from "react";
import styled from "styled-components";
import SlickSlider from "react-slick";
 

const SliderItem = styled.div`
  &:focus {
    outline: none;
  }
`;

const Slider = (props) => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed:3000
  };

  return (
      <><div id="howitworks" className="section how-it-works p-b-0 p-relative">
          <span className="bg-wrap"></span>
          <div className="container">

              <div className="section-title">
                  <h2>How it <span className="text-theme">Works </span></h2>
              </div>

              <div className="tab-wrapper">
                  <ul className="nav nav-tabs" role="tablist">
                      <li className="nav-item" role="presentation">
                          <a className={`btn tab-btn  m-r-10   ${(props.defaultType && props.defaultType == "1") ? '' : 'active'}`} data-bs-toggle="tab" href="#client" aria-selected={`  ${(props.defaultType && props.defaultType == "1") ? 'false' : 'true'}`} role="tab">Client</a>
                      </li>
                      <li className="nav-item" role="presentation">
                          <a className={`btn tab-btn    ${(props.defaultType && props.defaultType == "1") ? 'active' : ''}`} data-bs-toggle="tab" href="#experts" aria-selected={`  ${(props.defaultType && props.defaultType == "1") ? 'true' : 'false'}`} role="tab" tabindex="-1">Experts</a>
                      </li>
                  </ul>
              </div>

              <div className="expert-group-wrapper">
                  <div className="tab-content">

                      <div id="client" className={`tab-pane   ${(props.defaultType && props.defaultType == "1") ? '' : ' active show'}`} role="tabpanel">
                          <div className="step-wrapper">
                              <div className="card step-block p-relative">
                                  <img src="images/post-requ.svg" alt="post-requ" /> 

                                  <h6>Post your requirements</h6>
                                  <p>Describe your needs with precision using our user-friendly intake forms . Our cutting-edge AI assistant is here to assist you every step of the way.</p>
                                  <div className="count-block">
                                      <strong>Step 1</strong>
                                  </div>
                              </div>

                              <div className="card step-block p-relative">
                                  <img src="images/multiple-proposal.svg" alt="multiple-proposal" />
                                  <h6>Select from thousands expert’s proposal</h6>
                                  <p>Once you sign up, you'll gain immediate access to our vast global pool of vetted, verified compliance experts.</p>
                                  <div className="count-block">
                                      <strong>Step 2</strong>
                                  </div>
                              </div>

                              <div className="card step-block p-relative">
                                  <img src="images/best-experts.svg" alt="best-experts" />
                                  <h6>Elevate your solutions with the ideal expert</h6>
                                  <p>Screen, interview and confidently hire the ideal compliance expert for your organization.</p>
                                  <div className="count-block">
                                      <strong>Step 3</strong>
                                  </div>
                              </div>

                          </div>
                      </div>

                      <div id="experts" className={`tab-pane   ${(props.defaultType && props.defaultType == "1") ? ' active show' : ''}`} role="tabpanel">
                          <div className="step-wrapper">
                              
                              <div className="card step-block p-relative">
                                  <img src="images/create-profile.svg" alt="create-profile" />
                                  <h6>Create your profile</h6>
                                  <p>Simply fill out our user-friendly intake forms, providing detailed information about your background and credentials.</p>
                                  <div className="count-block">
                                      <strong>Step 1</strong>
                                  </div>
                              </div>

                              <div className="card step-block p-relative">
                                  <img src="images/post-requ.svg" alt="post-requ" />
                                  <h6>Access project requirements</h6>
                                  <p>Once approved, You’ll unlock access to a stream of exciting projects from our thriving global client community.</p>
                                  <div className="count-block">
                                      <strong>Step 2</strong>
                                  </div>
                              </div>

                              <div className="card step-block p-relative">
                                  <img src="images/get-hiried.svg" alt="get-hiried" />
                                  <h6>Get hired</h6>
                                  <p>Apply now and position yourself to provide top-notch services to companies worldwide.</p>
                                  <div className="count-block">
                                      <strong>Step 3</strong>
                                  </div>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
          <div id="howitworkss" className="bg-athens pattern-1 bg-image pt-13 pt-lg-10 pb-10 pb-lg-10 overflow-hidden d-none">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 z-index-2 pt-lg-20 pb-lg-25 py-10 px-10 "
              data-aos="fade-in"
              data-aos-duration="1000"
            >
                          <SlickSlider {...settings} className="howitworks-slider-one  ">
                              <SliderItem className="single-slider no-gutters">
                                  <div  className="pt-5 pt-lg-10 pb-7 pb-lg-18 ">
                                      <div className="container">
                                          {/* <!-- Section title --> */}
                                          <div className="row justify-content-center">
                                              <div className="col-12 col-md-8 col-lg-6 col-xxl-5">
                                                  <div className="text-center mb-8 mb-lg-7 px-xl-9 px-xxl-7">
                                                      <h2 className="font-size-8 mb-10 text-black">
                                                       How it works - Clients
                  <br className="d-none d-sm-block" />                 </h2>
                                                      <p className="font-size-4 text-default-color px-xs-9 px-md-0">
                                                         
                </p>
                                                  </div>
                                              </div>
                                          </div>
                                          {/* <!-- End Section title --> */}
                                          {/* <!-- Services Content --> */}
                                          <div
                                              className="row justify-content-center  d-xl-block d-lg-block  d-sm-none d-md-none d-none"
                                              data-aos="fade-up"
                                              data-aos-duration="1000"
                                          >
                                              {/* <!-- Single Services --> */}
                                              <div className="col-12 col-lg-12 col-md-12 col-sm-8 col-xs-8">
                                                  <div className="row w-80 step-devider  pt-10">
                                                      <div className="square-92 line-height-reset col-lg-4 rounded-4 bg-dodger text-white font-size-8 mx-auto shadow-blue mb-11  ">
                                                          <h2 className="text-white">1</h2> 
                                                      </div>   <div className="pt-10" >

                                                          <i className="fa fa-arrow-right text-gray  font-size-6"></i>

                                                      </div>
                                                      <div className="square-92 rounded-4 line-height-reset col-lg-4 text-white   bg-casablanca font-size-8 mx-auto shadow-casablanca mb-11">
                                                          <h2 className="text-white">2</h2>
                                                      </div> <div className="pt-10" >

                                                          <i className="fa fa-arrow-right text-gray  font-size-6"></i>

                                                      </div>
                                                      <div className="square-92 rounded-4 col-lg-4 line-height-reset bg-green  text-white font-size-8 mx-auto shadow-green mb-11 ">
                                                          <h2 className="text-white">3</h2>
                                                      </div>

                                                  </div>


                                              </div>
                                              {/* <!-- End Single Services --> */}

                                              {/* <!-- End Single Services --> */}

                                              {/* <!-- Single Services --> */}

                                              <div className="col-12 col-lg-12 col-md-12 col-sm-8 col-xs-8  ">
                                                  <div className="row w-90   ">
                                                      <div className=" col-lg-4 col-xl-4 col-md-6 col-sm-8 col-xs-8  ">
                                                          <div className="px-xl-7 px-xxl-12   text-center ">



                                                              <div className="services-content ">
                                                                 

                                                                  <h3 className="font-size-6 mb-7">Post your requirements</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      	Use our easy to use intake forms to describe your compliance needs. Get more specific for a best suited suggestion. 
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {/* <!-- End Single Services --> */}
                                                      <div className="bg-white" >

                                                          {' '}

                                                      </div>
                                                      {/* <!-- Single Services --> */}
                                                      <div className=" col-lg-4 col-xl-4 col-md-6 col-sm-8 col-xs-8 line-height-reset">
                                                          <div className="px-xl-7 px-xxl-12    text-center ">

                                                              <div className="services-content pl-5">
                                                                
                                                                  <h3 className="font-size-6 mb-7"> Select from thousands of expert’s proposals</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Chat with the pool of prescreened freelancers and request proposals based your budget.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {/* <!-- End Single Services --> */}
                                                      <div className="bg-white " >

                                                          {'  '}

                                                      </div>
                                                      {/* <!-- Single Services --> */}
                                                      <div className=" col-lg-4 col-xl-4 col-md-6 col-sm-8 col-xs-8">
                                                          <div className="px-xl-7 px-xxl-12     text-center  ">

                                                              <div className="services-content pl-5">
                                                                 
                                                                  <h3 className="font-size-6 mb-7">Hire the best expert </h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      	Select expert and initiate contract.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {/* <!-- End Single Services --> */} </div></div>

                                          </div>


                                          <div
                                              className="row justify-content-center px-md-20 d-xl-none d-lg-none d-sm-block d-md-block"
                                              data-aos="fade-up"
                                              data-aos-duration="1000"
                                          >    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                  <div className="row w-80 step-vertical-devider pt-10">
                                                      {/* <!-- Single Services --> */}
                                                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                          <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center ">
                                                              <div className="square-92 rounded-4 bg-dodger text-white font-size-8 mx-auto shadow-blue mb-11  ">
                                                                  <h2 className="text-white">1</h2>
                                                              </div>
                                                              <div className="services-content ">
                                                                  
                                                                  <h3 className="font-size-6 mb-7"> Post your requirements</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Use our easy to use intake forms to describe your compliance needs. Get more specific for a best suited suggestion. 
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className=" col-12 pt-5 pb-5 "   >

                                                          <i className="fa fa-arrow-down text-green   font-size-8"></i>

                                                      </div>
                                                      {/* <!-- End Single Services --> */}
                                                      {/* <!-- Single Services --> */}
                                                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                          <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center ">
                                                              <div className="square-92 rounded-4 bg-  bg-casablanca text-white font-size-8 mx-auto shadow-  bg-casablanca mb-11">
                                                                  <h2 className="text-white">2</h2>
                                                              </div>
                                                              <div className="services-content">
                                                                 
                                                                  <h3 className="font-size-6 mb-7"> Select from thousands of expert’s proposals</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Chat with the pool of prescreened freelancers and request proposals based your budget.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="col-12  pt-5 pb-5 " >

                                                          <i className="fa fa-arrow-down text-gray  font-size-8"></i>

                                                      </div>
                                                      {/* <!-- End Single Services --> */}
                                                      {/* <!-- Single Services --> */}
                                                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                          <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center  ">
                                                              <div className="square-92 rounded-4 bg-green text-white font-size-8 mx-auto shadow-green mb-11 ">
                                                                  <h2 className="text-white">3</h2>
                                                              </div>
                                                              <div className="services-content">
                                                                 
                                                                  <h3 className="font-size-6 mb-7"> Hire the best expert</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      	Select expert and initiate contract.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {/* <!-- End Single Services --> */}

                                                  </div>

                                              </div>




                                          </div>
                                          {/* <!-- End Services Content --> */}
                                      </div>
                                  </div>
                </SliderItem>
                              <SliderItem className="single-slider no-gutters ">
                                  <div id="howitworks" className="pt-5 pt-lg-10 pb-7 pb-lg-18">
                                      <div className="container">
                                          {/* <!-- Section title --> */}
                                          <div className="row justify-content-center">
                                              <div className="col-12 col-md-8 col-lg-6 col-xxl-5">
                                                  <div className="text-center mb-8 mb-lg-7 px-xl-9 px-xxl-7">
                                                      <h2 className="font-size-9 mb-10 text-black">
                                                          How it works - Experts
                  
                </h2>
                                                      <p className="font-size-4 text-default-color px-xs-9 px-md-0">
                                                       
                </p>
                                                  </div>
                                              </div>
                                          </div>
                                          {/* <!-- End Section title --> */}
                                          {/* <!-- Services Content --> */}
                                          <div
                                              className="row justify-content-center  d-xl-block d-lg-block  d-sm-none d-md-none d-none"
                                              data-aos="fade-up"
                                              data-aos-duration="1000"
                                          >
                                              {/* <!-- Single Services --> */}
                                              <div className="col-12 col-lg-12 col-md-12 col-sm-8 col-xs-8">
                                                  <div className="row w-80 step-devider pt-10">
                                                      <div className="square-92 line-height-reset col-lg-4 rounded-4 bg-dodger text-white font-size-8 mx-auto shadow-blue mb-11  ">
                                                          <h2 className="text-white">1</h2>
                                                      </div>   <div className="pt-10" >

                                                          <i className="fa fa-arrow-right text-gray  font-size-6"></i>

                                                      </div>
                                                      <div className="square-92 rounded-4 line-height-reset col-lg-4 text-white   bg-casablanca font-size-8 mx-auto shadow-casablanca mb-11">
                                                          <h2 className="text-white">2</h2>
                                                      </div> <div className="pt-10" >

                                                          <i className="fa fa-arrow-right text-gray  font-size-6"></i>

                                                      </div>
                                                      <div className="square-92 rounded-4 col-lg-4 line-height-reset bg-green  text-white font-size-8 mx-auto shadow-green mb-11 ">
                                                          <h2 className="text-white">3</h2>
                                                      </div>

                                                  </div>


                                              </div>
                                              {/* <!-- End Single Services --> */}

                                              {/* <!-- End Single Services --> */}

                                              {/* <!-- Single Services --> */}

                                              <div className="col-12 col-lg-12 col-md-12 col-sm-8 col-xs-8">
                                                  <div className="row w-90   ">
                                                      <div className=" col-lg-4 col-xl-4 col-md-6 col-sm-8 col-xs-8">
                                                          <div className="px-xl-7 px-xxl-12   text-center ">



                                                              <div className="services-content ">
                                                                  

                                                                  <h3 className="font-size-6 mb-7"> Create your profile</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Fill out an online form to join the exclusive SyncupPro community.  Every application is reviewed and approved by our moderation team, ensuring only the best freelancers are matched to client projects.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {/* <!-- End Single Services --> */}
                                                      <div className="bg-white" >

                                                          {' '}

                                                      </div>
                                                      {/* <!-- Single Services --> */}
                                                      <div className=" col-lg-4 col-xl-4 col-md-6 col-sm-8 col-xs-8 line-height-reset">
                                                          <div className="px-xl-7 px-xxl-12    text-center ">

                                                              <div className="services-content">
                                                                 
                                                                  <h3 className="font-size-6 mb-7">Access project requirement</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Once approved, you’ll gain access to a stream of projects from global client community.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {/* <!-- End Single Services --> */}
                                                      <div className="bg-white " >

                                                          {'  '}

                                                      </div>
                                                      {/* <!-- Single Services --> */}
                                                      <div className=" col-lg-4 col-xl-4 col-md-6 col-sm-8 col-xs-8">
                                                          <div className="px-xl-7 px-xxl-12     text-center  ">

                                                              <div className="services-content pl-5">
                                                                  
                                                                  <h3 className="font-size-6 mb-7">Get Hired</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Now you are ready to apply and get hired to provide service to companies globally.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {/* <!-- End Single Services --> */} </div></div>

                                          </div>


                                          <div
                                              className="row justify-content-center px-md-20 d-xl-none d-lg-none d-sm-block d-md-block"
                                              data-aos="fade-up"
                                              data-aos-duration="1000"
                                          >    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                  <div className="row w-80 step-vertical-devider pt-10">
                                                      {/* <!-- Single Services --> */}
                                                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                          <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center ">
                                                              <div className="square-92 rounded-4 bg-dodger text-white font-size-8 mx-auto shadow-blue mb-11  ">
                                                                  <h2 className="text-white">1</h2>
                                                              </div>
                                                              <div className="services-content ">
                                                                 
                                                                  <h3 className="font-size-6 mb-7">Create your profile</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Fill out an online form to join the exclusive SyncupPro community.  Every application is reviewed and approved by our moderation team, ensuring only the best freelancers are matched to client projects.</p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className=" col-12 pt-5 pb-5 "   >

                                                          <i className="fa fa-arrow-down text-green   font-size-8"></i>

                                                      </div>
                                                      {/* <!-- End Single Services --> */}
                                                      {/* <!-- Single Services --> */}
                                                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                          <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center ">
                                                              <div className="square-92 rounded-4 bg-  bg-casablanca text-white font-size-8 mx-auto shadow-  bg-casablanca mb-11">
                                                                  <h2 className="text-white">2</h2>
                                                              </div>
                                                              <div className="services-content">
                                                                 
                                                                  <h3 className="font-size-6 mb-7">Access project requirement</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Once approved, you’ll gain access to a stream of projects from global client community.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="col-12  pt-5 pb-5 " >

                                                          <i className="fa fa-arrow-down text-gray  font-size-8"></i>

                                                      </div>
                                                      {/* <!-- End Single Services --> */}
                                                      {/* <!-- Single Services --> */}
                                                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                          <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center  ">
                                                              <div className="square-92 rounded-4 bg-green text-white font-size-8 mx-auto shadow-green mb-11 ">
                                                                  <h2 className="text-white">3</h2>
                                                              </div>
                                                              <div className="services-content">
                                                                  
                                                                  <h3 className="font-size-6 mb-7">Get Hired</h3>
                                                                  <p className="font-size-4 text-default-color">
                                                                      Now you are ready to apply and get hired to provide service to companies globally.
                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      {/* <!-- End Single Services --> */}

                                                  </div>

                                              </div>




                                          </div>
                                          {/* <!-- End Services Content --> */}
                                      </div>
                                  </div>
                </SliderItem>
                             
              </SlickSlider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
