import React, { useState, useEffect } from "react";
import { logoutUser, GetExpertProfile, GetClientProfile, GetExpertPreviewLoad, GetClientPreviewProfile } from "../utils/apiCalls";
import { PaymentRetrieveAccount } from "../utils/payment";
import {GetCountry } from "../utils/GetAPIUtils";
import useTabActive from "../context/RefreshTab.js";
import { useLocation } from 'react-router-dom';
const GlobalContext = React.createContext();

const GlobalProvider = ({ children }) => {
    const [userInfo, setUserInfo] = useState({ email: "", userType: "", sub: "", profileInfo: null, userID: null, paymentConnected:false });
    const [userLocation, setUserLocation] = useState(null);
    const [defaultUserType, setDefaultUserType] = useState("");
    const [themeDark, setThemeDark] = useState(false);
    const [showSidebarDashboard, setShowSidebarDashboard] = useState(true);
    const [applicationModalVisible, setApplicationModalVisible] = useState(false);
    const [signInModalVisible, setSignInModalVisible] = useState(false);
    const [contactModalVisible, setContactModalVisible] = useState(false);
    const [forgotPasswordModalVisible, setForgotPasswordModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [errorModal, setErrorModal] = useState('');
    const [infoModalVisible, setInfoModalVisible] = useState(false);
    const [signUpModalVisible, setSignUpModalVisible] = useState(false);
    const [loaderModalVisible, setLoaderModalVisible] = useState(false);
    const [showBot, toggleBot] = useState(false);
    const [showBotBubble, toggleBubble] = useState(false);
    const [generalModalVisible, setGeneralModalVisible] = useState(false);
    const [general, setGeneral] = useState('expert');
    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [visibleOffCanvas, setVisibleOffCanvas] = useState(false);
    const [currentPage, setCurrentPage] = useState('');
    const [countriesList, setCountriesList] = useState([{}]);
    const [visibleProfileMessage, setVisibleProfileMessage] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [confirmModal, setconfirmModal] = useState({ text: "", confirmed: false });
    const [jobsDetail, setJobsDetail] = useState({ currentJobId: null });
    const [refreshDashboard, setRefreshDashboard] = useState("");
    const [msgCount, setMsgCount] = useState(0);
    const [alertCount, setAlertCount] = useState(0);
    const [paginationSize, setPaginationSize] = useState(5);
    const pathName = useLocation().pathname;
    const [userMode, setUserMode] = useState(null);
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [header, setHeader] = useState({
        theme: "light",
        bgClass: "default",
        variant: "primary",
        align: "left",
        isFluid: false,
        button: "cta", // profile, account, null
        buttonText: "Get started free", // profile, account, null
        reveal: true,
    });
    const [footer, setFooter] = useState({
        theme: "dark",
        style: "style1", //style1, style2
    });


    const toggleRefreshDashboard = (mode) => {
        setRefreshDashboard(mode);
    };
    useEffect(() => {
        if (pathName.toLowerCase().trim() == "/logout") { logout(); return; }

        
        LoadCountry();

        if (localStorage.getItem("connect")) {         
            const user = JSON.parse(localStorage.getItem("connect"));
            if (typeof (user.payload) != "undefined")
                getProfileData(user.payload["custom:usertype"], user.payload["email"], user.payload["sub"]);
          
        }

    

      
        if (navigator.geolocation) {
            window.navigator.geolocation.watchPosition(showPosition);
        } else {
            //console.log("The Browser Does not Support Geolocation");
        }


    }, []);
    function showPosition(position) {
        
       // console.log("Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude);
    }
    async function setPaymentStatus(id) {

        const detail = await PaymentRetrieveAccount(id);

        if (detail.data.paymentRetrieveAccount.responseData.code == 200) {
            // details_submitted, charges_enabled
            let data = JSON.parse(detail.data.paymentRetrieveAccount.responseData.message);
            return data;
        }
        else
            return false;
        
    }
    async function LoadCountry() {
        try {
            if (countriesList.length <= 1 && (pathName.toLowerCase().trim() == "/profile" || pathName.toLowerCase().trim() == "/dashboard" || pathName.toLowerCase().trim() == "/searchjob" || pathName.toLowerCase().trim() == "/searchexpert")) {
              
                const resultCountry = await GetCountry();
                if (resultCountry && resultCountry.data && resultCountry.data.countries) {
                    let countlist = [];
                    let usCountry = resultCountry.data.countries.filter(item => item.code == "US");
                    let list = resultCountry.data.countries.filter(item => item.code != "US");

                    usCountry.map((value) => (
                        countlist.push({ code: value.code, value: value.id, label: value.name })));

                    list.map((value) => (
                        countlist.push({ code: value.code, value: value.id, label: value.name })));
                  
                    setCountriesList(countlist);
                    
                }
            }
        } catch (e) {
            console.log(e);

        }
    }

    async function getUpdatedInfo(userType, email, sub) {
        if (userType == "1") {
            try {

                const result = await GetExpertPreviewLoad(sub);
                let data = JSON.parse(result.data.expertAllLoad.responseData.message);
                if (result.data.expertAllLoad.responseData.code == "200" && data.length > 0) {
                    let status;
                    if (data && data.length > 0 && data[0].stripeid) {
                          status = await setPaymentStatus(data[0].stripeid);
                    }
                    setUserInfo({ email: email, userType: userType, ["profileInfo"]: data, sub: sub, userID: (data.length > 0) ? data[0].id : null, idVerified: (data.length > 0) ? data[0].idverified : false, profileComplete: (data.length > 0) ? data[0].profilecomplete : false, appliedJobIds: (data.length > 0) ? data[0].appliedJobIds : [], savedJobIds: (data.length > 0) ? data[0].savedJobIds : [], paymentConnected: status});
                    return data[0].savedJobIds;
                }
                return [];
            }
            catch (e) {
                console.log(e);
                return [];
            }
        }

    }
    async function getProfileInfo(userType, email, sub) {
        if (userType == "1") {
            try {

                const result = await GetExpertPreviewLoad(sub);
                let data = JSON.parse(result.data.expertAllLoad.responseData.message);
             
                if (result.data.expertAllLoad.responseData.code == "200" && data.length > 0) {

                    if (data.length > 0 && data[0].deleted && !userMode && pathName.toLowerCase().trim() != "/deletemessage") { exitUser(); window.location.href = "/deletemessage"; return; }
                    let paymentDetail;
                    let status;
                    if (data && data.length > 0 && data[0].stripeid) {
                        paymentDetail = await setPaymentStatus(data[0].stripeid);
                       // console.log("data[0].stripeid", data[0].stripeid);
                       // status = paymentDetail.details_submitted && paymentDetail.charges_enabled;
                        status = paymentDetail;
                    }
                    setUserInfo({ email: email, userType: userType, ["profileInfo"]: data, sub: sub, userID: (data.length > 0) ? data[0].id : null, idVerified: data[0].idverified, profileComplete: data[0].profilecomplete, appliedJobIds: (data[0].appliedJobIds) ? data[0].appliedJobIds : [], savedJobIds: (data.length > 0) ? data[0].savedJobIds : [], paymentConnected: status, emailsenabled: (data.length > 0) ? data[0].emailsenabled : true, marketingemailsenabled: (data.length > 0) ? data[0].marketingemailsenabled : true, messagesenabled: (data.length > 0) ? data[0].messagesenabled:true });
                    setCurrentPage('5');
                }
                else {
                    setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, idVerified: false, profileComplete: false, paymentConnected: false , emailsenabled:   true, marketingemailsenabled:  true, messagesenabled:   true });
                    setCurrentPage('5');
                }
            }
            catch (e) {
                console.log(e);
                setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, idVerified: false, profileComplete: false, paymentConnected: false, emailsenabled: true, marketingemailsenabled: true, messagesenabled: true });
                setCurrentPage('5');
            }
        }
        else if (userType == "2") {
            try {

                const result = await GetClientPreviewProfile();
                let response = result.data.clientAllLoad.responseData.message;
                const data = JSON.parse(response);
              //  console.log("data", data);
                
                if (result.data.clientAllLoad && result.data.clientAllLoad.responseData.code == "200" && data.length > 0) {
                   

                    if (data.length > 0 && data[0].deleted && !userMode && pathName.toLowerCase().trim() != "/deletemessage")
                    { exitUser();  window.location.href = "/deletemessage"; return; }
                   
                    let status;
                    let paymentDetail;
                    if (data && data.length > 0 && data[0].stripeid) {
                        paymentDetail = await setPaymentStatus(data[0].stripeid);
                      //  console.log(paymentDetail);
                        //  status = paymentDetail.details_submitted && paymentDetail.charges_enabled
                        status = paymentDetail;
                    }
                    setUserInfo({ email: email, userType: userType, sub: sub, ["profileInfo"]: response, userID: (data.length > 0) ? data[0].id : null, idVerified: data[0].idverified, profileComplete: data[0].profilecomplete, paymentConnected: status, emailsenabled: (data.length > 0) ? data[0].emailsenabled : true, marketingemailsenabled: (data.length > 0) ? data[0].marketingemailsenabled : true, messagesenabled: (data.length > 0) ? data[0].messagesenabled : true, paymentInfo: paymentDetail?.external_accounts ? paymentDetail?.external_accounts : null });

                }
                else {
                    setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, idVerified: false, profileComplete: false, paymentConnected: false, emailsenabled: true, marketingemailsenabled: true, messagesenabled: true });
                    setCurrentPage('5');
                }
            }
            catch (e) {
                console.log(e);
                setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, idVerified: false, profileComplete: false, paymentConnected: false, emailsenabled: true, marketingemailsenabled: true, messagesenabled: true });
                setCurrentPage('5');
            }
        }
    }

    async function getProfileData(userType, email, sub) {

      //  console.log(userType, email, sub);
        if (userType == "1") {
            try {
               
                const result = await GetExpertPreviewLoad(sub);
                let data = JSON.parse(result.data.expertAllLoad.responseData.message);
                
                if (result.data.expertAllLoad && result.data.expertAllLoad.responseData.code == "200" && data.length > 0) {
                   
                    let status;
                    if (data && data.length > 0 && data[0].stripeid) {
                        let paymentDetail = await setPaymentStatus(data[0].stripeid);
                        // console.log("data[0].stripeid", data[0].stripeid);
                        //status = (paymentDetail.details_submitted && paymentDetail.charges_enabled) ? true : false;                       
                        status = paymentDetail;
                    }           
                    
                    setUserInfo({ email: email, userType: userType, ["profileInfo"]: data, sub: sub, userID: (data.length > 0) ? data[0].id : null, idVerified: (data.length > 0) ? data[0].idverified : false, profileComplete: (data.length > 0) ? data[0].profilecomplete : false, appliedJobIds: (data.length > 0) ? data[0].appliedJobIds : [], savedJobIds: (data.length > 0) ? data[0].savedJobIds : [], paymentConnected: status, emailsenabled: (data.length > 0) ? data[0].emailsenabled : true, marketingemailsenabled: (data.length > 0) ? data[0].marketingemailsenabled : true, messagesenabled: (data.length > 0) ? data[0].messagesenabled : true });

                    if (data.length > 0 && data[0].deleted && !userMode && pathName.toLowerCase().trim() != "/deletemessage")
                    { exitUser(); window.location.href = "/deletemessage"; return; }

                        if (pathName.toLowerCase() == "/auth" && data.length > 0) {

                            if (data[0].idverified && data[0].profilecomplete) {
                                window.location.href = "/SearchJob";
                            }
                            else {
                                window.location.href = "/profile";
                                setCurrentPage('5');
                            }
                        }
                        else if (pathName.toLowerCase() == "/auth") {
                            window.location.href = "/profile";
                            setCurrentPage('5');
                        }
                        else if (pathName.toLowerCase() == "/profile") {

                            setCurrentPage('5');
                        }
                        else {
                            //setCurrentPage('5');
                        }
                    
                    
                }
                else {
                    setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, paymentConnected: false, emailsenabled: true, marketingemailsenabled: true, messagesenabled: true });
                    if (pathName.toLowerCase() == "/auth") {
                        window.location.href = "/profile";
                        setCurrentPage('5');
                    }
                }
            }
            catch (e) {
                console.log(e);
                setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, paymentConnected: false, emailsenabled: true, marketingemailsenabled: true, messagesenabled: true });

                setCurrentPage('5');
            }
             
        }
        else if (userType == "2") {
            try {

                const result = await GetClientPreviewProfile();
                
                if (result.data.clientAllLoad && result.data.clientAllLoad.responseData.code == "200") {
                    let response = result.data.clientAllLoad.responseData.message;
                    const data = JSON.parse(response); 
                    let status;
                    let paymentDetail;
                    if (data && data.length > 0 && data[0].stripeid) {
                        paymentDetail = await setPaymentStatus(data[0].stripeid); 
                        status = paymentDetail;
                        //status = (paymentDetail.details_submitted && paymentDetail.charges_enabled)?true:false;                       
                    }
                    setUserInfo({ email: email, userType: userType, sub: sub, ["profileInfo"]: response, userID: (data.length > 0) ? data[0].id : null, idVerified: (data.length > 0) ? data[0].idverified : false, profileComplete: (data.length > 0) ? data[0].profilecomplete : false, paymentConnected: status, emailsenabled: (data.length > 0) ? data[0].emailsenabled : true, marketingemailsenabled: (data.length > 0) ? data[0].marketingemailsenabled : true, messagesenabled: (data.length > 0) ? data[0].messagesenabled : true, paymentInfo: paymentDetail?.external_accounts ? paymentDetail?.external_accounts:null });
                    if (data.length > 0 && data[0].deleted && !userMode && pathName.toLowerCase().trim() != "/deletemessage")
                    { exitUser(); window.location.href = "/deletemessage"; return; }
                    if (pathName.toLowerCase() == "/auth" && data.length > 0) {
                        if (data[0].idverified && data[0].profilecomplete) {
                            window.location.href = "/dashboard";
                        }
                        else {
                            window.location.href = "/profile";
                            setCurrentPage('5');
                        }
                    }
                    else {
                        if (pathName.toLowerCase() == "/profile" && data.length > 0) {
                            setCurrentPage('5');
                        } else if (pathName.toLowerCase() == "/dashboard" && data.length > 0) { setCurrentPage('1'); }
                        else if (pathName.toLowerCase() == "/auth") {
                            window.location.href = "/profile";
                        } 
                    }
                }
                else {
                    setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, paymentConnected: false, emailsenabled: true, marketingemailsenabled: true, messagesenabled: true });
                    if (pathName.toLowerCase() == "/auth") {
                        window.location.href = "/profile";
                    }
                    setCurrentPage('5');
                }
            }
            catch (e) {
                console.log(e);
                setUserInfo({ email: email, userType: userType, sub: sub, profileInfo: null, userID: null, paymentConnected: false, emailsenabled: true, marketingemailsenabled: true, messagesenabled: true });
                setCurrentPage('5');
            }

        }
    }

    const toggleTheme = () => {
        setThemeDark(!themeDark);
    };


    const toggleSidebarDashboard = () => {
        setShowSidebarDashboard(!showSidebarDashboard);
    };
    const toggleConfirmModal = () => {
        setConfirmModalVisible(!confirmModalVisible);
    };
    const toggleVideoModal = () => {
        setVideoModalVisible(!videoModalVisible);
    };

    const toggleApplicationModal = () => {
        setApplicationModalVisible(!applicationModalVisible);
    };

    const toggleSignInModal = () => {
        setSignInModalVisible(!signInModalVisible);
    };

    const toggleSignUpModal = () => {
        setSignUpModalVisible(!signUpModalVisible);
    };

    const toggleGeneralModal = () => {
        setGeneralModalVisible(!generalModalVisible);
    };

    const toggleOffCanvas = () => {
        setVisibleOffCanvas(!visibleOffCanvas);
    };

    const closeOffCanvas = () => {

        setVisibleOffCanvas(false);
    };
    const toggleSuccessModal = () => {

        setSuccessModalVisible(!successModalVisible);
    };
    const toggleErrorModal = () => {

        setErrorModalVisible(!errorModalVisible);
    };
    const toggleErrorMessage = (flag) => {

        setErrorModalVisible(flag);
    };
    const toggleInfoModal = () => {
        setInfoModalVisible(!infoModalVisible);
    };
    const toggleContactModal = () => {
        setContactModalVisible(!contactModalVisible);
    };
    const toggleForgotPasswordModal = () => {
        setForgotPasswordModalVisible(!forgotPasswordModalVisible);
    };
    function exitUser() {
        logoutUser();
        localStorage.removeItem('connect'); 
        localStorage.removeItem('userProfile');
    }
    function logout() {
        logoutUser();
        localStorage.removeItem('connect');
        localStorage.removeItem('userProfile');
        window.location.href = "/";
    }
    useEffect(() => {
        //if (currentPage == 5) {
        //    if (localStorage.getItem("connect")) {
        //        const user = JSON.parse(localStorage.getItem("connect"));                
        //        if (typeof (user.payload) != "undefined" && )
        //            getProfileInfo(user.payload["custom:usertype"], user.payload["email"], user.payload["sub"]);
        //    }
        //}

    }, [currentPage]);
    return (
        <>
            <GlobalContext.Provider
                value={{
                    themeDark,
                    toggleTheme,
                    showSidebarDashboard,
                    toggleSidebarDashboard,
                    videoModalVisible,
                    toggleVideoModal,
                    applicationModalVisible,
                    toggleApplicationModal,
                    signInModalVisible,
                    toggleSignInModal,
                    signUpModalVisible,
                    toggleGeneralModal,
                    generalModalVisible,
                    toggleSignUpModal,
                    errorModalVisible,
                    general,
                    setGeneral,
                    setErrorModal,
                    errorModal,
                    toggleErrorModal,
                    toggleErrorMessage,
                    infoModalVisible,
                    toggleInfoModal,
                    toggleContactModal,
                    contactModalVisible,
                    toggleForgotPasswordModal,
                    forgotPasswordModalVisible,
                    visibleOffCanvas,
                    toggleOffCanvas,
                    closeOffCanvas,
                    header,
                    setHeader,
                    footer,
                    setFooter,
                    userMode, setUserMode,
                    countriesList, setCountriesList, currentPage, setCurrentPage,
                    LoadCountry, showBotBubble, toggleBubble,  showBot, toggleBot, pageLoading, setPageLoading, defaultUserType, setDefaultUserType, toggleSuccessModal, successMessage, setSuccessMessage, successModalVisible, setSuccessModalVisible, paginationSize, setPaginationSize, alertCount, setAlertCount, msgCount, setMsgCount, toggleRefreshDashboard, refreshDashboard, setRefreshDashboard, getUpdatedInfo, loaderModalVisible, setLoaderModalVisible, setUserInfo, userLocation, setUserLocation, jobsDetail, setJobsDetail, userInfo, setUserInfo, logout, exitUser, getProfileInfo, setVisibleProfileMessage, visibleProfileMessage, toggleConfirmModal, confirmModalVisible, setConfirmModalVisible, confirmModal, setconfirmModal
                }}
            >
                {children}
            </GlobalContext.Provider>
            {useTabActive()}
        </>
    );
};

export default GlobalContext;
export { GlobalProvider };
